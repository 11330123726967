import { UserManager, WebStorageStateStore, User } from 'oidc-client';

export default class AuthService {
    private userManager: UserManager;

    constructor() {
        const STS_DOMAIN: string = 'https://localhost:44301/';

        const settings: any = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: STS_DOMAIN,
            client_id: 'PostmanImplicit',
            redirect_uri: 'https://test.qeyif.com/callback.html',
            automaticSilentRenew: false,
            silent_redirect_uri: 'https://test.qeyif.com/silent-renew.html',
            response_type: 'token',
            scope: 'OmurgaClientId_api',
            post_logout_redirect_uri: 'https://test.qeyif.com/',
            filterProtocolClaims: false,
        };

        this.userManager = new UserManager(settings);
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect();
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }

    public getAccessToken(): Promise<string> {
        return this.userManager.getUser().then((data: any) => {
            return data.access_token;
        });
    }
}
